import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './Products.css';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import Servicios from '../Servicios/Servicios.css'
import 'react-toastify/dist/ReactToastify.css';
import ProductosLoading from '../ProductosLoading/ProductosLoading';
import { Link as Anchor } from "react-router-dom";
import moneda from '../moneda';
import img1 from '../../images/servicios.png'
import img2 from '../../images/sistemas.png'
import img3 from '../../images/marketing.png'
import img4 from '../../images/camara.png'
SwiperCore.use([Navigation, Pagination, Autoplay]);

export default function Products() {
    const [categorias, setCategorias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fixedCategories, setFixedCategories] = useState(false);
    const categoriasRefs = useRef([]);
    const categoriasInputRef = useRef(null);
    const swiperRef = useRef(null);
    const [productos, setProductos] = useState([]);
    const [categoriaSeleccionada, setCategoriaSeleccionada] = useState('Todo');
    const [servicioActivo, setServicioActivo] = useState(null);

    const handleClickCategoria = (categoria, nombreCategoria = '') => {
        setCategoriaSeleccionada(categoria);

        // Encuentra el primer servicio que coincida con la categoría seleccionada
        const servicioCorrespondiente = servicios.find(servicio => servicio.categoria === nombreCategoria);
        setServicioActivo(servicioCorrespondiente ? servicioCorrespondiente.id : null);
    };



    useEffect(() => {
        cargarProductos();
        cargarCategorias();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if (categoriasInputRef.current) {
            if (window.scrollY > categoriasInputRef.current.offsetTop) {
                setFixedCategories(true);
            } else {
                setFixedCategories(false);
            }
        }
    };


    const cargarProductos = () => {
        fetch(`${baseURL}/productosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setProductos(data.productos);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar productos:', error));
    };

    const cargarCategorias = () => {
        fetch(`${baseURL}/categoriasGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setCategorias(data.categorias || []);
            })
            .catch(error => console.error('Error al cargar categorías:', error));
    };

    const obtenerImagen = (item) => {
        if (item.imagen1) {
            return item.imagen1;
        } else if (item.imagen2) {
            return item.imagen2;
        } else if (item.imagen3) {
            return item.imagen3;
        } else if (item.imagen4) {
            return item.imagen4;
        }
        return null;
    };

    const categoriasConProductos = categorias.filter(categoria =>
        productos?.some(producto => producto?.idCategoria === categoria?.idCategoria)
    );

    const servicios = [
        {
            id: 1,
            imagen: img1,
            categoria: 'SERVICIOS',
            nombre: 'Luyo Casani',
            descripcion: `¿Problemas con tú COMPUTADORA LAPTOP-IMPRESORA? nosotros,hacemos que tus equipos recobren vida.`,
            telefono: 51961514338,
            linkCatalogo: 'https://drive.google.com/file/d/1xFzc0UtcJo_20d-aVki4J-7WahB7WLAd/view',
            comentario: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x91101721af4a508f:0x5778a4ce85cd4fb1!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation'
        },
        {
            id: 2,
            imagen: img2,
            categoria: 'SISTEMAS',
            nombre: 'Rafael Chavez',
            descripcion: `¿Eres Empresaria (o) necesitas desarrollar tú sistema desde cero,"Mi objetivo es crear un sistemas único e incomparable para tí.`,
            telefono: 51924786384,
            linkCatalogo: 'https://drive.google.com/file/d/1BImMmPQ0Smo36vLyF7HL5UNm6PeQlFlr/view',
            comentario: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x91101721af4a508f:0x5778a4ce85cd4fb1!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation'
        },
        {
            id: 3,
            imagen: img3,
            categoria: 'MARKETING',
            nombre: 'Lixy Ortiz',
            descripcion: `¿Tienes un negocio o empresa? Como experta en marketing empresarial,ofrezco una asesoría GRATUITA,Trabajemos juntos para llevar tú empresa al siguiente nivel`,
            telefono: 51924786384,
            linkCatalogo: 'https://drive.google.com/file/d/1AicwNiiQUGbZHk3O6c49mNH0AxDg2xX6/view',
            comentario: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x91101721af4a508f:0x5778a4ce85cd4fb1!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation'
        },
        {
            id: 4,
            imagen: img4,
            categoria: 'CAMARA',
            nombre: 'Javier Vargas',
            descripcion: `¿Preocupado por la seguridad de tú casa o Negocio? nos especializamos en Instalación de Camaras de Seguridad,manteniminto Preventivo, Venta,protege lo que mas importa.`,
            telefono: 51924786384,
            linkCatalogo: 'https://drive.google.com/file/d/1jUdWPf9YGoxttVv60SYPt7xaG0dUfDQ1/view',
            comentario: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x91101721af4a508f:0x5778a4ce85cd4fb1!12e1?source=g.page.m.kd._&laa=lu-desktop-review-solicitation'
        },
    ]
    const handleServicioClick = (servicio) => {
        if (servicioActivo === servicio.id) {
            // Si el servicio ya está activo, desactívalo
            setServicioActivo(null);
        } else {
            // Si el servicio no está activo, actívalo
            setServicioActivo(servicio.id);
            const categoriaSeleccionada = categorias.find(cat => cat.categoria === servicio.categoria)?.idCategoria || 'Todo';
            handleClickCategoria(categoriaSeleccionada, servicio.categoria);
        }
    };




    return (
        <div className='ProductsContain'>
            <div className='Servicios'>

                {servicios?.map((servicio) => (
                    <div
                        key={servicio.id}
                        className={`servicio ${servicio.id === servicioActivo ? 'activeSwiper' : ''}`}
                        onClick={() => handleServicioClick(servicio)}
                    >
                        <h2>{servicio.categoria}</h2>
                        <img src={servicio.imagen} alt={servicio.nombre} />
                        <h3>{servicio.nombre}</h3>
                        <p>{servicio.descripcion} <br />¡Escribeme!</p>

                        <div className='deFlexBtnServis'>
                            <button onClick={() => window.open(`https://wa.me/${servicio.telefono}`)} className='wppBtnServ'>Whatsapp</button>
                            <Anchor to={servicio.linkCatalogo} target="_blank" rel="noopener noreferrer" className='btnServ'>
                                Catálogo
                            </Anchor>
                        </div>
                        <Anchor to={servicio.comentario} target="_blank" rel="noopener noreferrer" className='btnServ'>
                            Comentario
                        </Anchor>
                    </div>

                ))}

            </div>

            {productos?.length > 0 && (
                <div className={`categoriasInputs ${fixedCategories ? 'fixed' : ''}`} ref={categoriasInputRef}>
                    <input
                        type="button"
                        value="Todo"
                        onClick={() => handleClickCategoria('Todo')}
                        style={{
                            backgroundColor: categoriaSeleccionada === 'Todo' ? '#2529a2' : '',
                            color: categoriaSeleccionada === 'Todo' ? '#fff' : '',
                            borderBottom: categoriaSeleccionada === 'Todo' ? '2px solid #2529a2' : 'none'
                        }}
                    />
                    {categoriasConProductos.map(({ categoria, idCategoria }) => (
                        <input
                            key={idCategoria}
                            type="button"
                            value={categoria}
                            onClick={() => handleClickCategoria(idCategoria)}
                            style={{
                                backgroundColor: categoriaSeleccionada === idCategoria ? '#2529a2' : '',
                                color: categoriaSeleccionada === idCategoria ? '#fff' : '',
                                borderBottom: categoriaSeleccionada === idCategoria ? '2px solid #2529a2' : 'none'
                            }}
                        />
                    ))}
                </div>
            )}
            {loading ? (
                <ProductosLoading />
            ) : (
                <div className='Products'>
                    {categoriaSeleccionada === 'Todo' && (
                        <>
                            {productos?.some(item => item.masVendido === "si") && (
                                <div className='categoriSection'>
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        slidesPerView={'auto'}
                                        id='swiper_container_products'
                                        autoplay={{ delay: 3000 }}
                                    >
                                        {productos?.filter(item => item.masVendido === "si").map(item => (
                                            <SwiperSlide key={item.idProducto} id='SwiperSlide-scroll-products-masvendidos'>
                                                <Anchor className='cardProdcutmasVendido' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <img src={obtenerImagen(item)} alt="imagen" />
                                                    <h6 className='masVendido'>Más Vendido</h6>
                                                    <div className='cardText'>
                                                        <h4>{item.titulo}</h4>
                                                        <span>{item.descripcion}</span>
                                                        <div className='deFLexPrice'>
                                                            <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                                <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Anchor>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            )}
                            {categoriasConProductos?.map(({ categoria, idCategoria }) => (
                                <div key={idCategoria} className='categoriSection' ref={ref => categoriasRefs.current[categorias.findIndex(cat => cat.idCategoria === idCategoria)] = ref}>
                                    <div className='deFlexTitlesection'>
                                        <h3>{categoria}</h3>
                                        <button onClick={() => handleClickCategoria(idCategoria)}>
                                            Ver más
                                        </button>
                                    </div>
                                    <Swiper
                                        effect={'coverflow'}
                                        grabCursor={true}
                                        slidesPerView={'auto'}
                                        id='swiper_container_products'
                                    >
                                        {productos?.filter(item => item.idCategoria === idCategoria).map(item => (
                                            <SwiperSlide id='SwiperSlide-scroll-products' key={item.idProducto}>
                                                <Anchor className='cardProdcut' key={item.idProducto} to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                                    <img src={obtenerImagen(item)} alt="imagen" />
                                                    <div className='cardText'>
                                                        <h4>{item.titulo}</h4>
                                                        <span>{item.descripcion}</span>
                                                        <div className='deFLexPrice'>
                                                            <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                                <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                            )}
                                                        </div>
                                                        <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                                    </div>
                                                </Anchor>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                            ))}
                        </>
                    )}
                    {categoriaSeleccionada !== 'Todo' && (
                        <div className='categoriSectionSelected'>
                            {productos?.filter(item => item.idCategoria === categoriaSeleccionada).map(item => (
                                <div key={item.idProducto} className='productCard'>
                                    <Anchor className='cardProdcutSelected' to={`/producto/${item.idProducto}/${item.titulo.replace(/\s+/g, '-')}`}>
                                        <img src={obtenerImagen(item)} alt="imagen" />
                                        <div className='cardText'>
                                            <h4>{item.titulo}</h4>
                                            <span>{item.descripcion}</span>
                                            <div className='deFLexPrice'>
                                                <h5> {moneda} {String(item?.precio)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                {(item.precioAnterior >= 1 && item.precioAnterior !== undefined) && (
                                                    <h5 className='precioTachado'>{moneda} {`${item?.precioAnterior}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</h5>
                                                )}
                                            </div>
                                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconCard' />
                                        </div>
                                    </Anchor>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    );


}
